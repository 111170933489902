import SweepsMoesFooterData from "../../data/Footer/SweepsMoesFooter.data"

export default function SweepsMoesFooter() {
  return (
    <footer className="main-footer">
      <div className="nav-wrapper">
        <div className="container">
          <img
            src={SweepsMoesFooterData.logo.path}
            alt={SweepsMoesFooterData.logo.alt}
            className="logo"
          />
          <div className="site-tools">
            <nav className="auxiliary-navigation">
              <ul>
                {SweepsMoesFooterData.tools.map(link => {
                  return (
                    <li className="nav-item">
                      <a
                        href={link.href}
                        className={[
                          "nav-link",
                          link.thirdParty ? "fcs-third-party" : ""
                        ].join(" ")}
                        target="_blank"
                      >
                        {link.text}
                        {link.thirdParty && (
                          <img src="/sweepsmoes/external-white.png"></img>
                        )}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="nav-wrapper secondary">
        <div className="container">
          <p>© 2023 Moe's Franchisor SPV LLC</p>
          <div className="site-tools">
            <nav className="auxiliary-navigation">
              <ul>
                {SweepsMoesFooterData.copyright.map((link, index) => {
                  if (link.hasTruyoPrivacyOptions) {
                    return (
                      <li 
                        key={index}
                        className="nav-item"
                      >
                        <a 
                          id="cookieSettingButton" 
                          className="nav-link cookieSettingButton"
                          href="#" 
                          style={{
                          }}>
                            YOUR PRIVACY CHOICES
                            <span className="privacy-icon">
                              <img src="/common/privacy-icon.svg" />
                            </span>
                          </a>
                      </li>
                    )
                  }

                  return (
                    <li 
                      key={index}
                      className="nav-item"
                    >
                      <a
                        href={link.href}
                        className={[
                          "nav-link",
                          link.thirdParty ? "fcs-third-party" : ""
                        ].join(" ")}
                        target="_blank"
                      >
                        {link.text}
                        {link.thirdParty && (
                          <img src="/sweepsmoes/external-white.png"></img>
                        )}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  )
}