export default {
  logo: {
    path: "/sweepsmoes/moes-logo.png",
    alt: "Moe's Logo"
  },
  tools: [
    {
      href: "https://development.focusbrands.com/",
      text: "Franchise Information",
      thirdParty: true

    },
    {
      href: "https://www.moes.com/about",
      text: "About" 
    },
    {
      href: "https://www.moes.com/gift-cards",
      text: "Gift Cards"
    },
    {
      href: "https://www.moes.com/grocery-products",
      text: "Grocery Products"
    },
    {
      href: "https://www.moes.com/careers",
      text: "Careers"
    },
    {
      href: "https://www.moes.com/media",
      text: "Newsroom",
    },
    {
      href: "https://www.moes.com/faqs",
      text: "FAQ"
    },
    {
      href: "https://www.moes.com/contact-us",
      text: "Contact Us",
      thirdParty: true

    },
  ],
  copyright: [
    {
      href: "https://www.moes.com/site-map",
      text: "Sitemap"
    },
    {
      href: "https://www.moes.com/legal",
      text: "Legal"
    },
    {
      href: "https://www.moes.com/privacy",
      text: "Privacy"
    },
    {
      href: "https://www.moes.com/caprivacy",
      text: "CA Privacy"
    },
    {
      hasTruyoPrivacyOptions: true
    },
    {
      href: "https://www.focusbrands.com/animal-welfare",
      text: "Animal Welfare",
      thirdParty: true
    },
    {
      href: "https://www.focusbrands.com/accessibility/moes",
      text: "Accessibility",
      thirdParty: true
    },
  ]
}