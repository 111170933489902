export const SuccessLandingData = {
  moes: {
    showHero: true,
    heroBackgroundImage: "/moes/hero.jpg",
    showBgOnMobile: true,
    downloadTitle: "Thank you for linking your Moe Rewards account with your AARP membership.​",
    downloadSubtitle:
      "The discount is placed in your Moe Rewards account daily and there is no need to link your account again. Follow these instructions to place an order online, in-person or within the Moe’s Southwest Grill app.​",
    howItWorks: `
      <div class="row mt-medium">
        <div class="col">
          <div class="card-component secondary-style" style="text-align: left">
            <div class="content-wrapper">
              <p style="font-size: 18px;"><strong>To place an order on <a href="https://moes.com" target="_blank">Moes.com</a>:</strong></p> </br>
              <ol class="numbered-list">
                <li>Go to <a href="https://moes.com" target="_blank">Moes.com</a> & click on “Order Now”, then sign into your account using your email and password.​</li>
                <li>Find the Moe’s Southwest Grill location where you want to place your order, then click “Begin Order”.​​</li>
                <li>Select the desired order products by clicking “Add to Cart”.​</li>
                <li>Once you have placed all your items in your cart, click “Rewards Available”, then find the AARP member offer, then click “Apply Reward”.​​</li>
                <li>With the 10% discount applied, click the cart icon and your order details will appear.​​​</li>
                <li>You can now click “Checkout” to complete your order.​​​</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-component secondary-style" style="text-align: left">
            <div class="content-wrapper">
              <p style="font-size: 18px;"><strong>To place an order in-person, you must use the Moe’s Southwest Grill app:​​​</strong></p> </br>
              <ol class="numbered-list">
                <li>Download the app in the Apple Store or Google Play using buttons below</li>
                <li>Sign into your Moe Rewards account using your email and password.</li>
                <li>Once signed into your Moe Rewards account, tap the three lines in the upper right corner, then tap “rewards”. ​​</li>
                <li>Your rewards will appear. Scroll to find the AARP member Offer, then tap “Redeem In-Store”.​​</li>
                <li>A QR code will appear. The cashier will scan your QR code to apply your discount to your order.​​​</li>
               </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-component secondary-style" style="text-align: left">
            <div class="content-wrapper">
              <p style="font-size: 18px;"><strong>To place an order ahead of time using the Moe’s Southwest Grill app:​​</strong></p> </br>
              <ol class="numbered-list">
                <li>Download the app in the Apple Store or Google Play using buttons below.​</li>
                <li>Sign into your Moe Rewards account using your email and password.</li>
                <li>Once signed into your Moe Rewards account, tap “Start Order”.​</li>
                <li>Select your order type, then select the location from which you would like to place your order.​</li>
                <li>Find the food items you would like to purchase, then tap “Add to Cart”. Once you have made your selection(s), tap bag icon in the lower right corner. Your order summary will appear. ​</li>
                <li>Scroll down to find the AARP member offer and tap “Apply”.​</li>
                <li>Your order summary will re-appear, then tap “Proceed to Checkout” to complete your purchase.​</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    `,
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.moes.com/?utm_source=aarp&utm_medium=aff&utm_campaign=fy22_cnv_olo_brand_aarp-microsite-success-page&utm_term=new-customers&utm_content=order-now ",
      target: "_blank",
    },
    hideOrderAndLocationButtons: true,
    locationSubtitle: "",
    locationButton: {
      text: "",
      URL: "",
      target: "_blank",
    },
    showBenefitsSection: false,
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Moe Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering or third-party delivery orders.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affiliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
  },
  moestmobile: {
    showHero: true,
    heroBackgroundImage: "/moes/hero.jpg",
    showBgOnMobile: true,
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "SUCCESS! NOW DOWNLOAD THE MOE REWARDS APP TO ACCESS YOUR OFFER",
    downloadSubtitle: "​",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center"><strong>You must follow the below instructions to access your offer.</strong></p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download the app in the Apple Store or Google Play using buttons below.</li>
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/moe-rewards/id1008719375" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.punchh.moes&hl=en_US&gl=US" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        <li>Sign into your Moe Rewards account using your email and password.</li>
        <br />
        <li>Once signed into your Moe Rewards account, tap "rewards" and navigate to "Deals" to find your offer.</li>
        <br />
        <li>Your rewards and offers will appear. Scroll to find the T-Mobile Tuesdays offer.</li>
      </ol>
    `,
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://www.jamba.com/menu",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Jamba",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.jamba.com/",
      target: "_blank",
    },
    showBenefitsSection: false,
    hideOrderAndLocationButtons: true,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
      disclaimerText:
      `<div class="container disclaimer-additional"><p>
The buy one burrito or bowl, get one free offer is valid through November 17, 2024, at 11:59 p.m. ET, or close of business. Taxes and fees excluded. Discount applies to item of equal or lesser value. Limit 1 offer/person/loyalty account. Not valid with any other promotion. No substitutions. Service fee applies to online, in-app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void if altered, transferred, reproduced, exchanged, sold, purchased or where restricted by law. Price may vary; cash value 1/100 of 1 cent.​</p><br /><p>©2024 Moe’s Franchisor SPV LLC. All rights reserved. T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG​</p></div>`,
  },
  schlotzskys: {
    showHero: true,
    heroBackgroundImage: "/schlotzskys/hero.jpg",
    showBgOnMobile: true,
    downloadTitle: "Thank you for linking your Schlotzsky’s Rewards account with your AARP membership.​",
    downloadSubtitle:
      "The discount is placed in your Schlotzsky's Rewards account daily and there is no need to link your account again. Follow these instructions to place an order online, in-person or within the Schlotzsky’s app.​",
    hideOrderAndLocationButtons: true,
    showBenefitsSection: false,
    howItWorks: `
    <div class="row mt-medium">
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order on <a href="https://www.schlotzskys.com/" target="_blank">Schlotzskys.com</a>:</strong></p> </br>
            <ol class="numbered-list">
              <li>Go to <a href="https://schlotzskys.com" target="_blank">Schlotzskys.com</a> & click on “Sign In” using your email and password.​</li>
              <li>To find the Schlotzsky’s location where you want to place your order, enter the city, then click “Start Order”, the click “Begin Order” for your location.​​</li>
              <li>Select the desired order products by clicking “Add to Cart”. ​​</li>
              <li>Once you have placed all your items in your cart, click “Rewards Available”, then find the AARP member offer, then click “Apply Reward”.​​​</li>
              <li>With the 10% discount applied, click the cart icon and your order details will appear.​​​</li>
              <li>You can now click “Checkout” to complete your order.​​​</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order in-person, you must use the Schlotzsky’s app:​​</strong></p> </br>
            <ol class="numbered-list">
              <li>Download the app in the Apple Store or Google Play using buttons below</li>
              <li>Sign into your Schlotzsky’s Rewards account using your email and password. ​</li>
              <li>Once signed into your Schlotzsky’s Rewards account, tap “rewards”. ​​</li>
              <li>Your rewards will appear. Scroll to find the AARP member Offer, then tap “Redeem”.​​</li>
              <li>A QR code will appear. The cashier will scan your QR code to apply your discount to your order.​​​</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order ahead of time using the Schlotzsky’s app:​​</strong></p> </br>
            <ol class="numbered-list">
              <li>Download the app in the Apple Store or Google Play using buttons below.</li>
              <li>Sign into your Schlotzsky’s Rewards account using your email and password. ​</li>
              <li>Once signed into your Schlotzsky’s Rewards account, tap “Order”.​​</li>
              <li>You will be asked to select a location. Next, you will be prompted to select either pickup, delivery or drive-through along with the time you would like your order ready. Make your selections, then tap “choose food”. Find the items you would like to order and then tap “Add to Bag”.​​</li>
              <li>Once you have finished adding items to you want, tap “Review Order”. Then tap “Make Payment”. In the available offers section, tap “Redeem”. Tap on the AARP member offer from your list of offers, then tap “Redeem”. You will be asked to confirm you want to use the AARP member offer on this order, tap “Apply.”​​​</li>
              <li>Your order summary will appear with your check reduced by 10%. Then tap “Pay Now” to complete your order.​​​​</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  `,
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for Schlotzsky's Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering, gift cards or third-party delivery orders.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affilliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
  },
  schlotzskysauntieannes: {
    showHero: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/schlotzskys/sc-cs-hero.png",
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "/schlotzskys/sc-cs-hero.png",
    spotlightImageAlt: "Schlotzskys Rewards",
    downloadTitle: "Thank you for joining Schlotzsky's Rewards!",
    downloadSubtitle:
      "The easiest way to get started will be to download our app and sign into your account!",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.schlotzskys.com/?utm_source=aarp&utm_medium=aff&utm_campaign=fy22_cnv_olo_brand_aarp-microsite-success-page&utm_term=new-customers&utm_content=order-now",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Schlotzsky's",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.schlotzskys.com/?utm_source=aarp&utm_medium=aff&utm_campaign=fy22_con_omni_brand_aarp-microsite-success-page&utm_term=new-customers&utm_content=location-search",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/schlotzskys/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/schlotzskys/offer.png",
        imageAlt: "Free Chips & Drink",
        title: "Free Chips & Drink",
        description:
          "You paired your accounts, now let us pair your entrée with chips and a drink!",
        note: "",
      },
      {
        imageURL: "/schlotzskys/birthday.png",
        imageAlt: "Birthday Bonus",
        title: "Birthday Bonus",
        description: "Dine with us on the big day! Get a free sandwich when you spend $1.",
        note: "",
      },
    ],
    rewardsDisclaimer: "",
    disclaimerText: "",
  },
  mcalisters: {
    showHero: true,
    heroBackgroundImage: "/fetchmca/hero.jpg",
    showBgOnMobile: true,
    downloadTitle: "Thank you for linking your McAlister’s Rewards account with your AARP membership.​",
    downloadSubtitle:
      "The discount is placed in your McAlister’s Rewards account daily and there is no need to link your account again. Follow these instructions to place an order online, in-person or within the McAlister’s Deli app.​",
    howItWorks: `
      <div class="row mt-medium">
        <div class="col">
          <div class="card-component secondary-style" style="text-align: left">
            <div class="content-wrapper">
              <p style="font-size: 18px;"><strong>To place an order on <a href="https://mcalistersdeli.com" target="_blank">McAlistersdeli.com</a>:</strong></p> </br>
              <ol class="numbered-list">
                <li>Go to <a href="https://mcalistersdeli.com" target="_blank">McAlistersdeli.com</a> & sign into your account using your email and password.​</li>
                <li>Click “start order” and select “Pickup” or “Delivery”.​​</li>
                <li>Find the McAlister’s Deli location where you want to place your order, select it, & proceed to “Start Ordering”.​​</li>
                <li>Select the desired order products by clicking “Add to Basket” & when finished go to your basket and click “Checkout”.​​​</li>
                <li>Your order details will appear. ​​​</li>
                <li>To redeem your 10% AARP member offer, scroll down until it appears and click “Apply”.​​</li>
                <li>With the 10% discount will be applied, you can now click “Checkout” to complete your order.​​​</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-component secondary-style" style="text-align: left">
            <div class="content-wrapper">
              <p style="font-size: 18px;"><strong>To place an order in-person, you must use the McAlister’s Deli app:​​</strong></p> </br>
              <ol class="numbered-list">
                <li>Download the app in the Apple Store or Google Play using buttons below.​</li>
                <li>Sign into your McAlister’s Rewards account using your email and password. ​</li>
                <li>Once signed into your McAlister’s Rewards account, tap the three lines in the upper right corner, then tap “rewards”. ​​</li>
                <li>Once on the Rewards screen, tap “Special Offers”. Scroll to find the AARP member Offer, then tap “Redeem In-Store”.​​</li>
                <li>A QR code will appear. The cashier will scan your QR code to apply your discount to your order.​​​</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-component secondary-style" style="text-align: left">
            <div class="content-wrapper">
              <p style="font-size: 18px;"><strong>To place an order ahead of time using the McAlister’s Deli app:​</strong></p> </br>
              <ol class="numbered-list">
                <li>Download the app in the Apple Store or Google Play using buttons below.​</li>
                <li>Sign into your McAlister’s Rewards account using your email and password. ​</li>
                <li>Once signed into your McAlister’s Rewards account, tap “Start Order”.​​</li>
                <li>Select your order type, then select the location from which you would like to place your order.​​</li>
                <li>Find the food items you would like to purchase, then tap “Next”. Once you have made your selection(s), tap “Add to Basket”. When finished adding items to your basket, tap on the basket icon in the lower right corner. Your order summary will appear. ​​</li>
                <li>Scroll down to find the AARP member offer and tap “Apply”.​​​</li>
                <li>Once the discount has been applied, tap “Go to Check Out”. Your order summary will re-appear, then tap “Place Order” to complete your purchase. ​​​​</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    `,
    hideOrderAndLocationButtons: true,
    showBenefitsSection: false,
    rewardsDisclaimer:
      "*10% Off AARP Reward valid for McAlister's Rewards Members who link their AARP member account. Offer valid for online, in-app or in-restaurant orders. Must apply reward at checkout. One time use per day per guest. One reward per purchase. Cannot be combined with any other offers, promotions, or discounts. Offer not valid for catering, gift cards or third-party delivery orders.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affilliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
  },
  auntieannes: {
    showHero: true,
    heroBackgroundImage: "/auntieannes/hero.png",
    showBgOnMobile: true,
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Auntie Anne's Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy! Please note, it may take up to 60 minutes before you see the discount in your Auntie Anne’s Rewards account.",
      },
    ],
    howItWorks: `
    <div class="row mt-medium">
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order on <a href="https://auntieannes.com" target="_blank">AuntieAnnes.com</a>:</strong></p> </br>
            <ol class="numbered-list">
              <li>Go to <a href="https://auntieannes.com" target="_blank">AuntieAnnes.com</a> & click “Order Now”, then click on “Sign In” using your email and password.​</li>
              <li>To find the Auntie Anne’s location where you want to place your order, enter the city, then click “Start Order”, then click “Begin Order” for your location.​</li>
              <li>Select the desired order products by clicking “Add to Cart”.</li>
              <li>Once you have placed all your items in your cart, click “Rewards Available”, then find the AARP member offer, then click “Apply Reward”.​</li>
              <li>With the 10% discount applied, click the cart icon and your order details will appear.​</li>
              <li>You can now click “Checkout” to complete your order.​</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order in-person, you must use the Auntie Anne’s app:​</strong></p> </br>
            <ol class="numbered-list">
              <li>Download the app in the Apple Store or Google Play using buttons below</li>
              <li>Sign into your Auntie Anne’s Rewards account using your email and password. ​</li>
              <li>Once signed into your Auntie Anne’s Rewards account, tap “rewards”.</li>
              <li>Your rewards will appear. Scroll to find the AARP member Offer, then tap “Redeem In Store”.</li>
              <li>A QR code will appear. The cashier will scan your QR code to apply your discount to your order.​</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order ahead of time using the Auntie Anne’s app:​</strong></p> </br>
            <ol class="numbered-list">
              <li>Download the app in the Apple Store or Google Play using buttons below.</li>
              <li>Sign into your Auntie Anne’s Rewards account using your email and password.</li>
              <li>Once signed into your Auntie Anne’s Rewards account, tap “Order”.​</li>
              <li>Make your pickup or delivery selection, then the time you want to pick up or have your order delivered, and your address, then tap “Search for Locations”. Find the location from which you would like to order and tap “Begin Order”. Tap the items you wish to order.​</li>
              <li>Once you have found the items you want, tap “Add to Cart”. Then tap “Rewards Available”. Scroll down to find the AARP member offer and tap “Apply reward”. </li>
              <li>Once your reward has been applied, tap on the cart icon in the upper right corner. You will see your AARP member discount applied. Tap “Checkout” to complete your purchase.​</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  `,
    spotlightImageURL: "https://focusbrands-dc.azureedge.net/dmm3bwsv3/assetstream.aspx?assetid=2361&mediaformatid=50100&destinationid=10016&download=false",
    spotlightImageAlt: "Auntie Anne's Rewards",
    downloadTitle: "Thank you for linking your Auntie Anne’s Rewards account with your AARP membership.​",
    downloadSubtitle:
      "The discount is placed in your Auntie Anne’s Rewards account daily and there is no need to link your account again. Follow these instructions to place an order online, in-person or within the Auntie Anne’s app.​",
    hideOrderAndLocationButtons: true,
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://www.auntieannes.com/menu?_ga=2.110474949.1978808147.1655239686-1082051781.1642787109",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Auntie Anne's",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.auntieannes.com/?_ga=2.110016197.1978808147.1655239686-1082051781.1642787109",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Pretzel Perks Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "*10% off discount is valid at participating Auntie Anne's locations online, in app and in store. Offer is not valid on any catering orders or third-party delivery orders. This offer cannot be combined with any other offer. Member will receive one (1) 10% off discount per day. Exclusive of alcohol, tax, and gratuity. Members pays sales tax, subject to applicable law. Online and app orders subject to service fee and sales tax, where applicable. See checkout screen for amounts. In-store redemption not subject to service fee. Not for sale or resale. Void where prohibited. Cash value 1/100¢. No cash back. Additional exclusions may apply.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affilliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
  },

  fuelja: {
    showHero: true,
    heroBackgroundImage: "/jamba/fuel-rewards-hero.png",
    showBgOnMobile: true,
    spotlightID: "You're All Set!",
    spotlightTitle: "",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    howItWorks: `
      <p class="text-center mt-medium">To order, visit <a href="https://jamba.com" target="_blank">Jamba.com</a> or download the Jamba app today!</p>
      <div class="row mt-medium">
        <div class="col">
          <div class="card-component secondary-style">
            <div class="content-wrapper">
              <p><strong>To place an order on <a href="https://jamba.com" target="_blank">Jamba.com</a>:</strong></p>
              <ol class="numbered-list">
                <li><p>Go to <a href="https://jamba.com" target="_blank">Jamba.com</a> & select either “order delivery” or “order pickup”</p></li>
                <li><p>Sign into your Jamba Rewards account using your email and password</p></li>
                <li><p>Find the Jamba location where you want to place your order, select it, & confirm it</p></li>
                <li><p>Select the desired order products by clicking “add to order” & when finished go to your basket and click “checkout”</p></li>
                <li><p>Your order details will appear and you will be able to “continue”</p></li>
                <li><p>Submit your payment details & click “submit order”</p></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-component secondary-style">
            <div class="content-wrapper">
              <p><strong>To place an order in-person, you must use the Jamba app. Here’s how:</strong></p>
              <ol class="numbered-list">
                <li><p>Download the app in the Apple Store or Google Play using buttons below</p></li>
                <li><p>Sign into your Jamba Rewards account using your email and password </p></li>
                <li><p>Once signed into your Jamba Rewards account, tap “rewards”. </p></li>
                <li><p>Show the cashier the QR code within the app to apply your Jamba Rewards account to your order</p></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-component secondary-style">
            <div class="content-wrapper">
              <p><strong>To place an order ahead of time using the Jamba app:</strong></p>
              <ol class="numbered-list">
                <li><p>Download the app in the Apple Store or Google Play using buttons below</p></li>
                <li><p>Sign into your Jamba Rewards account using your email and password</p></li>
                <li><p>Make your pickup or delivery selection, then tap on the location from which you would like to order, the tap “select”.</p></li>
                <li><p>Tap the items you wish to order. You’re order details will appear, click “continue”</p></li>
                <li><p>Once your item has been added to your order, tap the basket icon in the lower right corner. You will be able to review your order, then tap “continue”</p></li>
                <li><p>Tap “place order” to complete your purchase</p></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    `,
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Thank you for linking your Jamba Rewards and Fuel Rewards® accounts.",
    downloadSubtitle: "You’ve earned a one-time 15¢/gal * discount at participating Shell stations. Save an additional 5¢/gal† for every $25 spent at Jamba from now through December 31, 2024.",
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: "",
    locationSubtitle: "Find your nearest Jamba",
    locationButton: "",
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
    `*Restrictions apply. Offer valid from November 13, 2023 – December 31, 2024 (the "Offer Period"). Earn a one-time 15¢/gal Fuel Rewards® savings up to 20 gallons (the "Linking Reward") when you successfully link your Fuel Rewards® account to your Jamba Rewards account. To qualify for this offer, during the Offer Period you must: (i) be a member of or join the JambaRewards program; (ii) be a member of or join the Fuel Rewards®program; (iii) link your Jamba Rewards account to your Fuel Rewards® account using the link above. After successful linking, swipe your Fuel Rewards® card, use a linked payment card, or enter your Alt ID when you fuel at a participating Shell station to redeem the Linking Reward. The Fuel Rewards® Linking Reward is single use and expires on the last day of the month following the month in which the Linking Reward was earned. Please see <a href="https://www.fuelrewards.com/status" target="_blank">fuelrewards.com/status</a> for complete Fuel Rewards® Tier Status details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
    <br /><br />
    *Restrictions apply. Offer valid from November 13, 2023 – December 31, 2024 (the "Offer Period"). Earn 5¢/gal in Fuel Rewards® savings up to 20 gallons (the “Spend and Get Reward”) when you spend $25.00 in qualifying purchases at participating Jamba locations. To qualify for this offer, (i) the user must have successfully linked their Jamba Rewards account to their Fuel Rewards® account; and (ii) spend $25.00 in qualifying purchases (excluding purchasing gift cards, taxes and fees) at Jamba using their Jamba Rewards account qualifying purchases may be completed via multiple transactions. Each time the Jamba User spends $25 in qualifying purchases, the user is issued a Spend and Get Reward. To redeem the Spend and Get Reward,use a linked payment card or enter your Alt ID when you fuel at a participating Shell station to redeem the reward. Spend and Get Reward will be added to your Fuel Rewards® account within two (2) to three (3) business days of completing each $25.00 qualifying purchase(s) at Jamba using your Jamba Rewards account. All Spend and Get Rewards will expire on the last day of the month, one (1) month after the month in which rewards are posted to your account. Please see <a href="https://www.fuelrewards.com/terms" target="_blank">fuelrewards.com/terms</a> for complete Fuel Rewards®  program details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
    <br /><br />
    For all offers listed above, these terms apply. Void where prohibited, only available in US. Fuel Rewards® savings are limited to 20 gallons of fuel per purchase per vehicle or fraud limits placed by Shell and/or limits placed on your payment card by your financial institution, each of which may be lower. Dispenser may require a price of up to $0.109/gallon. Once you begin to dispense fuel using your Fuel Rewards®savings, you must dispense to the 20-gallon limit or you forfeit any remaining discount per gallons. If you have a rewards balance greater than the current per-gallon price of fuel, the price will roll down to the maximum capability of the fuel equipment, and your remaining rewards balance will be saved for a future fuel purchase. For purchases of $75 or more, please go inside to pay. Unbranded diesel and alternative fuels may not be eligible. The Fuel Rewards® program is owned and operated by Excentus Corporation. Offers may be modified or discontinued at any time without notice. Not valid where prohibited by law. Please see <a href="https://www.fuelrewards.com/terms" target="_blank">fuelrewards.com/terms</a> for complete FuelRewards® program details and Terms and Conditions. Other restrictions may apply. All trademarks are property of their respective owners.`,
    disclaimerText:
      ""
  },

  fueljalto: {
    showHero: true,
    heroBackgroundImage: "/jamba/fuel-hero-lto.png",
    showBgOnMobile: true,
    spotlightID: "You're All Set!",
    spotlightTitle: "",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    howItWorks: `
      <p class="text-center mt-medium">To order, visit <a href="https://jamba.com" target="_blank">Jamba.com</a> or download the Jamba app today!</p>
      <div class="row mt-medium">
        <div class="col">
          <div class="card-component secondary-style">
            <div class="content-wrapper">
              <p><strong>To place an order on <a href="https://jamba.com" target="_blank">Jamba.com</a>:</strong></p>
              <ol class="numbered-list">
                <li><p>Go to <a href="https://jamba.com" target="_blank">Jamba.com</a> & select either “order delivery” or “order pickup”</p></li>
                <li><p>Sign into your Jamba Rewards account using your email and password</p></li>
                <li><p>Find the Jamba location where you want to place your order, select it, & confirm it</p></li>
                <li><p>Select the desired order products by clicking “add to order” & when finished go to your basket and click “checkout”</p></li>
                <li><p>Your order details will appear and you will be able to “continue”</p></li>
                <li><p>Submit your payment details & click “submit order”</p></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-component secondary-style">
            <div class="content-wrapper">
              <p><strong>To place an order in-person, you must use the Jamba app. Here’s how:</strong></p>
              <ol class="numbered-list">
                <li><p>Download the app in the Apple Store or Google Play using buttons below</p></li>
                <li><p>Sign into your Jamba Rewards account using your email and password </p></li>
                <li><p>Once signed into your Jamba Rewards account, tap “rewards”. </p></li>
                <li><p>Show the cashier the QR code within the app to apply your Jamba Rewards account to your order</p></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-component secondary-style">
            <div class="content-wrapper">
              <p><strong>To place an order ahead of time using the Jamba app:</strong></p>
              <ol class="numbered-list">
                <li><p>Download the app in the Apple Store or Google Play using buttons below</p></li>
                <li><p>Sign into your Jamba Rewards account using your email and password</p></li>
                <li><p>Make your pickup or delivery selection, then tap on the location from which you would like to order, the tap “select”.</p></li>
                <li><p>Tap the items you wish to order. You’re order details will appear, click “continue”</p></li>
                <li><p>Once your item has been added to your order, tap the basket icon in the lower right corner. You will be able to review your order, then tap “continue”</p></li>
                <li><p>Tap “place order” to complete your purchase</p></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    `,
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Thank you for linking your Jamba Rewards and Fuel Rewards® accounts.",
    downloadSubtitle: "You’ve earned a one-time 15¢/gal * discount at participating Shell stations. Save an additional 10¢/gal† for every $15 spent at Jamba from now through June 30, 2024.",
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: "",
    locationSubtitle: "Find your nearest Jamba",
    locationButton: "",
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
    `*Restrictions apply. Offer valid from May 24, 2024 – June 30, 2024 (the "Offer Period"). Earn a one-time 15¢/gal Fuel Rewards® savings up to 20 gallons (the "Linking Reward") when you successfully link your Fuel Rewards® account to your Jamba Rewards account. To qualify for this offer, during the Offer Period you must: (i) be a member of or join the JambaRewards program; (ii) be a member of or join the Fuel Rewards®program; (iii) link your Jamba Rewards account to your Fuel Rewards® account using the link above. After successful linking, swipe your Fuel Rewards® card, use a linked payment card, or enter your Alt ID when you fuel at a participating Shell station to redeem the Linking Reward. The Fuel Rewards® Linking Reward is single use and expires on the last day of the month following the month in which the Linking Reward was earned. Please see <a href="https://www.fuelrewards.com/status" target="_blank">fuelrewards.com/status</a> for complete Fuel Rewards® Tier Status details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
    <br /><br />
    *Restrictions apply. Offer valid from May 24, 2024 – June 30, 2024 (the "Offer Period"). Earn 10¢/gal in Fuel Rewards® savings up to 20 gallons (the “Spend and Get Reward”) when you spend $15.00 in qualifying purchases at participating Jamba locations. To qualify for this offer, (i) the user must have successfully linked their Jamba Rewards account to their Fuel Rewards® account; and (ii) spend $15.00 in qualifying purchases (excluding purchasing gift cards, taxes and fees) at Jamba using their Jamba Rewards account qualifying purchases may be completed via multiple transactions. Each time the Jamba User spends $15 in qualifying purchases, the user is issued a Spend and Get Reward. To redeem the Spend and Get Reward,use a linked payment card or enter your Alt ID when you fuel at a participating Shell station to redeem the reward. Spend and Get Reward will be added to your Fuel Rewards® account within two (2) to three (3) business days of completing each $15.00 qualifying purchase(s) at Jamba using your Jamba Rewards account. All Spend and Get Rewards will expire on the last day of the month, one (1) month after the month in which rewards are posted to your account. Please see <a href="https://www.fuelrewards.com/terms" target="_blank">fuelrewards.com/terms</a> for complete Fuel Rewards®  program details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
    <br /><br />
    For all offers listed above, these terms apply. Void where prohibited, only available in US. Fuel Rewards® savings are limited to 20 gallons of fuel per purchase per vehicle or fraud limits placed by Shell and/or limits placed on your payment card by your financial institution, each of which may be lower. Dispenser may require a price of up to $0.109/gallon. Once you begin to dispense fuel using your Fuel Rewards®savings, you must dispense to the 20-gallon limit or you forfeit any remaining discount per gallons. If you have a rewards balance greater than the current per-gallon price of fuel, the price will roll down to the maximum capability of the fuel equipment, and your remaining rewards balance will be saved for a future fuel purchase. For purchases of $75 or more, please go inside to pay. Unbranded diesel and alternative fuels may not be eligible. The Fuel Rewards® program is owned and operated by Excentus Corporation. Offers may be modified or discontinued at any time without notice. Not valid where prohibited by law. Please see <a href="https://www.fuelrewards.com/terms" target="_blank">fuelrewards.com/terms</a> for complete FuelRewards® program details and Terms and Conditions. Other restrictions may apply. All trademarks are property of their respective owners.`,
    disclaimerText:
      "",
  },
  jamba: {
    showHero: true,
    heroBackgroundImage: "/jamba/hero.png",
    showBgOnMobile: true,
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Thank you for linking your Jamba Rewards account with your AARP membership.",
    downloadSubtitle:
      "The discount is placed in your Jamba Rewards account daily and there is no need to link your account again. Follow these instructions to place an order online, in-person or within the Jamba app.​",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://www.jamba.com/menu",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Jamba",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.jamba.com/",
      target: "_blank",
    },
    showBenefitsSection: false,
    hideOrderAndLocationButtons: true,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    howItWorks: `
    <div class="row mt-medium">
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order on <a href="https://jamba.com" target="_blank">Jamba.com</a>:</strong></p> </br>
            <ol class="numbered-list">
              <li>Go to <a href="https://jamba.com" target="_blank">Jamba.com</a> & select either “order delivery” or “order pickup”.</li>
              <li>Sign into your Jamba Rewards account using your email and password.​</li>
              <li>Find the Jamba location where you want to place your order, select it, & confirm it.​</li>
              <li>Select the desired order products by clicking “add to order” & when finished go to your basket and click “checkout”.​​</li>
              <li>Your order details will appear and you will be able to “continue”.​​</li>
              <li>To redeem your 10% AARP member offer, scroll down until it appears and click “redeem”.​​</li>
              <li>Once you have “redeemed” your offer, the 10% discount will be applied.​​</li>
              <li>Submit your payment details & click “submit order”.​​</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order in-person, you must use the Jamba app. Here’s how:​​</strong></p> </br>
            <ol class="numbered-list">
              <li>Download the app in the Apple Store or Google Play using buttons below</li>
              <li>Sign into your Jamba Rewards account using your email and password.</li>
              <li>Once signed into your Jamba Rewards account, tap “rewards”. Scroll down to “my offers”, then tap “show more offers” to find the AARP member offer, then tap “View T&C”.​</li>
              <li>Your AARP member offer details will appear. Show the cashier your phone and provide them with the phone number associated with your Jamba Rewards account. The cashier will then apply your discount to your order.​</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order ahead of time using the Jamba app:​</strong></p> </br>
            <ol class="numbered-list">
              <li>Download the app in the Apple Store or Google Play using buttons below.</li>
              <li>Sign into your Jamba Rewards account using your email and password.</li>
              <li>Make your pickup or delivery selection, then tap on the location from which you would like to order, the tap “select”.​</li>
              <li>Tap the items you wish to order. You’re order details will appear, click “continue”.​</li>
              <li>Once your item has been added to your order, tap the basket icon in the lower right corner. You will be able to review your order, then tap “continue”.​​</li>
              <li>Scroll down to find your AARP member discount under payment methods, then tap “redeem”. Your discount will be applied.​​​</li>
              <li>Tap “place order” to complete your purchase.​​​</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  `,
    rewardsDisclaimer:
      "*10% off discount is valid at participating Jamba locations online, in app and in store. Excludes any Bruegger's Bagels stores, Las Vegas Strip, Safeway, Vons, Pavilions, convenience stores, airport or select university Jamba locations. Offer is not valid on any catering orders or third-party delivery orders. This offer cannot be combined with any other offer. Member will receive one (1) 10% off discount per day. Exclusive of alcohol, tax, and gratuity. Members pays sales tax, subject to applicable law. Online and app orders subject to service fee and sales tax, where applicable. See checkout screen for amounts. In-store redemption not subject to service fee. Not for sale or resale. Void where prohibited. Cash value 1/100¢. No cash back. Additional exclusions may apply.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affilliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
  },
  jambatmobile: {
    showHero: true,
    heroBackgroundImage: "/jamba/hero.jpg",
    showBgOnMobile: true,
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "SUCCESS! NOW LOG INTO JAMBA.COM TO ACCESS YOUR OFFER",
    downloadSubtitle:
      "​",
    howItWorks: `
    <br />
    <p class="info-wrapper text-center"><strong>You must follow the below instructions to access your offer.</strong></p>
    <br />
    <ol class="numbered-list text-center">
      <li>Visit <a href="https://jamba.com" target="_blank">Jamba.com</a></li>
      <br />
      <li>Tap "log in" in the upper right corner, then sign in to your Jamba Rewards account​</li>
      <br />
      <li>Tap the three lines icon in the upper left corner, then tap “rewards"</li>
      <br />
      <li>Scroll down and tap "view your account," then tap "see all rewards and offers"</li>
      <br />
      <li>Scroll down to my offers to find your perk, then tap to apply your discount</li>
    </ol>`,
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://www.jamba.com/menu",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Jamba",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.jamba.com/",
      target: "_blank",
    },
    showBenefitsSection: false,
    hideOrderAndLocationButtons: true,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
    `The $4 off Reward is valid through September 9, 2024, at 12:00 a.m. ET or close of business. Use of free Jamba Rewards Account required to redeem $4 off Reward. Taxes and fees excluded. Reward can be redeemed in the Jamba App, online at Jamba.com or in store at participating locations; not available in Puerto Rico or U.S. Virgin Islands, Bruegger's Bagels stores, Las Vegas Strip, Safeway, Vons, Pavilions, convenience stores, airports or select university Jamba locations. Limit 1 reward/customer, single use $4 off reward. Must apply Reward at checkout. Not valid with any other offer or reward or third-party delivery. Service fee applies to online, in app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. Reward not redeemable for cash, void if purchased, sold or bartered for cash, or if photocopied or reproduced. TM/© 2024 Jamba Juice Franchisor SPV LLC. All rights reserved.`,
  },
  cinnabon: {
    showHero: true,
    heroBackgroundImage: "/cinnabon/hero.jpg",
    showBgOnMobile: true,
    spotlightID: "You're All Set!",
    spotlightTitle: "",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "/cinnabon/spotlight.jpg",
    spotlightImageAlt: "Cinnabon Rewards",
    downloadTitle: "Thank you for linking your Cinnabon Rewards account with your AARP membership.​",
    downloadSubtitle:
      "The discount is placed in your Cinnabon Rewards account daily and there is no need to link your account again. Follow these instructions to place an order online, in-person or within the Cinnabon app.​",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://www.cinnabon.com/menu",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Cinnabon",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.cinnabon.com/",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "Cinnabon Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    hideOrderAndLocationButtons: true,
    showNoticesSection: true,
    notices1ImageURL: "/cinnabon/cinschlo.png",
    notices1ImageAlt: "Cinnabon and Schlotzskys",
    notices1Id: "",
    notices1Title:
      "Trying to use the discount at a Cinnabon that is within a Schlotzsky’s?",
    notices1Description:
      `You will need to link your AARP membership with your Schlotzsky's Rewards account to receive your discount. Visit <a href="schlotzskys.com/aarp" target="_blank">Schlotzskys.com/AARP</a> to link your account.`,
    notices2Id: "",
    notices2ImageURL: "/cinnabon/cinaa.jpg",
    notices2ImageAlt: "",
    notices2Title:
      "Trying to use the discount at a Cinnabon that is connected to an Auntie Anne’s?",
    notices2Description:
      `You will need to link your AARP membership with your Auntie Anne’s Rewards account to receive your discount. Visit <a href="auntieannes.com/aarp" target="_blank">AuntieAnnes.com/AARP</a> to link your account.`,
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    howItWorks: `
    <div class="row mt-medium">
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order on <a href="https://cinnabon.com" target="_blank">Cinnabon.com</a>:</strong></p> </br>
            <ol class="numbered-list" style="font-family: var(--quinaryType)">
              <li>Go to <a href="https://cinnabon.com" target="_blank">Cinnabon.com</a> & click “Order Now”, then click on “Sign In” using your email and password.​</li>
              <li>To find the Cinnabon location where you want to place your order, enter the city, then click “Start Order”, the click “Begin Order” for your location.​</li>
              <li>Select the desired order products by clicking “Add to Cart”.</li>
              <li>Once you have placed all your items in your cart, click “Rewards Available”, then find the AARP member offer, then click “Apply Reward”.​</li>
              <li>With the 10% discount applied, click the cart icon and your order details will appear.​​</li>
              <li>You can now click “Checkout” to complete your order.​​</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order in-person, you must use the Cinnabon app:​​</strong></p> </br>
            <ol class="numbered-list" style="font-family: var(--quinaryType)">
              <li>Download the app in the Apple Store or Google Play using buttons below</li>
              <li>Sign into your Cinnabon Rewards account using your email and password. ​</li>
              <li>Once signed into your Cinnabon Rewards account, tap “rewards”.​</li>
              <li>Your rewards will appear. Scroll to find the AARP member Offer, then tap “Redeem In Store”.​</li>
              <li>A QR code will appear. The cashier will scan your QR code to apply your discount to your order.​​</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card-component secondary-style" style="text-align: left">
          <div class="content-wrapper">
            <p style="font-size: 18px;"><strong>To place an order ahead of time using the Cinnabon app:​​</strong></p> </br>
            <ol class="numbered-list" style="font-family: var(--quinaryType)">
              <li>Download the app in the Apple Store or Google Play using buttons below.</li>
              <li>Sign into your Cinnabon Rewards account using your email and password.</li>
              <li>Once signed into your Cinnabon Rewards account, tap “Order”.​​</li>
              <li>Make your pickup or delivery selection, then the time you want to pick up or have your order delivered, and your address, then tap “Search for Locations”. Find the location from which you would like to order and tap “Begin Order”. Tap the items you wish to order.​​</li>
              <li>Once you have found the items you want, tap “Add to Cart”. Then tap “Rewards Available”. Scroll down to find the AARP member offer and tap “Apply reward”. ​</li>
              <li>Once your reward has been applied, tap on the cart icon in the upper right corner. You will see your AARP member discount applied. Tap “Checkout” to complete your purchase.​​</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  `,
    rewardsDisclaimer:
      "*10% off discount is valid at participating Cinnabon locations online, in app and in store. Offer is not valid on any catering orders or third-party delivery orders. This offer cannot be combined with any other offer. Member will receive one (1) 10% off discount per day. Exclusive of alcohol, tax, and gratuity. Members pays sales tax, subject to applicable law. Online and app orders subject to service fee and sales tax, where applicable. See checkout screen for amounts. In-store redemption not subject to service fee. Not for sale or resale. Void where prohibited. Cash value 1/100¢. No cash back. Additional exclusions may apply.",
    disclaimerText:
      "AARP Member Benefits are provided by third parties, not by AARP or its affilliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details.",
  },
  carvel: {
    pageTitle: "Hello Carvel Rewards!",
  },
  fetchmca: {
    showHero: true,
    showBgOnMobile: true,
    heroBackgroundImage: "/fetchmca/hero.jpg",
    spotlightID: "You're All Set!",
    spotlightTitle: "McAlister's Rewards App",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've signed up for McAlister's Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/mcalisters/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    downloadTitle: "THANK YOU FOR JOINING MCALISTER’S REWARDS!",
    downloadSubtitle: "",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center">Your Fetch points will be awarded to your Fetch account in 7-10 business days.</p>
      <br />
      <div class="numbered-list text-center">
        <p>To place your McAlister’s Deli order today, tap below to open the McAlister’s Deli app or visit <a href="https://www.mcalistersdeli.com" target="_blank">https://www.mcalistersdeli.com</a> ​</p>
        </br>
        <p>Sign into your McAlister’s Rewards account using your email and password to earn points on your purchase. ​</p>
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/mcalisters-deli/id475738160" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.snapfinger.mobile.mcalisters&hl=en_US&gl=US" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        <br />
      </div>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: undefined,
    locationSubtitle: "Find your nearest McAlister's Deli",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: `Offer available only to new McAlister’s Rewards Members who sign up using partners.mcalistersdeli.com/fetch on or before 12/31/2024 who have an active Fetch account. Must enroll in McAlister’s Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch points are controlled and distributed by Fetch, Inc. which is not associated with McAlister’s Deli or Focus Brands LLC and are subject to Fetch Terms and Conditions available at <a href="https://fetch.com/terms-of-service" target="_blank">https://fetch.com/terms-of-service</a>`,
    disclaimerText: "",
  },
  fetchaa: {
    showHero: true,
    showBgOnMobile: true,
    heroBackgroundImage: "/fetchaa/fetch-hero.jpg",
    spotlightID: "You're All Set!",
    spotlightTitle: "McAlister's Rewards App",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've signed up for McAlister's Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/mcalisters/spotlight.jpg",
    spotlightImageAlt: "Moe Rewards",
    downloadTitle: "THANK YOU FOR JOINING AUNTIE ANNE'S REWARDS!",
    downloadSubtitle: "",
    howItWorks: `
    <br />
    <p class="info-wrapper text-center">Your Fetch points will be awarded to your Fetch account in 7-10 business days.</p>
    <br />
    <br />
    <p class="info-wrapper text-center">Tap below to open the Auntie Anne's app and place your order today.</p>
    <br />
    <ol class="numbered-list text-center">
      <li>Download the app in the Apple Store or Google Play using buttons below.</li>
      <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
      <li>Sign into your Auntie Anne's Rewards account using your email and password.</li>
      <br />
      <li>Tap "order" to start your order.</li>
      <br />
    </ol>
  `,
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: undefined,
    locationSubtitle: "Find your nearest McAlister's Deli",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: `Offer available only to new Auntie Anne's Rewards Members who sign up using partners.auntieannes.com/fetch on or before 12/31/2024 who have an active Fetch account. Must enroll in Auntie Anne's Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch points are controlled and distributed by Fetch, Inc. which is not associated with Auntie Anne's or GoTo Foods LLC and are subject to Fetch Terms and Conditions available at <a href="https://fetch.com/terms-of-service" target="_blank">https://fetch.com/terms-of-service</a> `,
    disclaimerText: "",
  },
  fetchsl: {
    showHero: true,
    heroBackgroundImage: "/fetchsl/hero.jpg",
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    showBgOnMobile: true,
    downloadTitle: "THANK YOU FOR JOINING SCHLOTZSKY'S REWARDS!",
    downloadSubtitle: "​",
    howItWorks: `
      <br />
      <p class="text-center font-tertiary" style="font-size: 22px; margin-bottom: 20px;">Your Fetch points will be awarded to your Fetch account in 7-10 business days.</p>
      <br />
      <p class="text-center font-tertiary" style="font-size: 22px; margin-bottom: 20px;">Tap below to open the Schlotzsky’s app and place your order today.</p>
      <br />
      <ol class="info-wrapper numbered-list text-center" style="font-size: 18px">
        <li>Download the app in the Apple Store or Google Play using buttons below.
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/schlotzskys-rewards-program/id768163905" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.punchh.schlotzskys&hl=en_US&gl=US" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        </li>
        <li>Sign into your Schlotzsky’s Rewards account using your email and password.​</li>
        <br />
        <li>Tap "order" to start your order</li>
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: undefined,
    locationSubtitle: "Find your nearest SCHLOTZSKY'S",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: [],
    disclaimerText: `Offer available only to new Schlotzsky’s Rewards Members who sign up using partners.schlotzskys.com/fetch on or before 12/31/2024 who have an active Fetch account. Must enroll in Schlotzsky’s Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch points are controlled and distributed by Fetch, Inc. which is not associated with Schlotzsky’s or Focus Brands LLC and are subject to Fetch Terms and Conditions available at <a href="https://fetch.com/terms-of-service" target="_blank">https://fetch.com/terms-of-service</a>`,
  },
  fetchci: {
    showHero: true,
    heroBackgroundImage: "/fetchci/hero.png",
    showBgOnMobile: true,
    spotlightID: "",
    spotlightTitle: "Download the Cinnabon App.",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    downloadTitle: "Thank you for joining Cinnabon Rewards!",
    downloadSubtitle: "​",
    howItWorks: `
    <br />
    <p class="text-center font-tertiary" style="font-size: 22px; margin-bottom: 20px;">Your Fetch points will be awarded to your Fetch account in 7-10 business days.</p>
    <br />
    <p class="text-center font-tertiary" style="font-size: 22px; margin-bottom: 20px;"> Tap below to open the Cinnabon app and place your order today.</p>
    <br />
    <ol class="info-wrapper numbered-list text-center" style="font-size: 18px">
      <li>Download the app in the Apple Store or Google Play using buttons below.
      <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/cinnabon/id1532016072" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.cinnfocus.app" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
      </li>
      <li>Sign into your Cinnabon Rewards account using your email and password.​</li>
      <br />
      <li>Tap "order" to start your order</li>
    </ol>
  `,
    appTitle: "",
    buttonGroupTitle: "",
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: [],
    disclaimerText: `Offer available only to new Cinnabon Rewards Members who sign up using partners.cinnabon.com/fetch on or before 12/31/2024 who have an active Fetch account. Must enroll in Cinnabon Rewards using the email address associated with your Fetch account to earn Fetch points for enrollment. Fetch points will be awarded to your Fetch account 7-10 business days after enrollment. Fetch points are controlled and distributed by Fetch, Inc. which is not associated with Cinnabon or Focus Brands LLC and are subject to Fetch Terms and Conditions available at <a href="https://fetch.com/terms-of-service" target="_blank">https://fetch.com/terms-of-service</a>`
  },
  cinnabonmcalisters: {
    showHero: true,
    heroBackgroundImage: "/cinnabon/cin-cs-hero.png",
    spotlightID: "",
    spotlightTitle: "Download the Cinnabon App.",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    downloadTitle: "Thank you for joining Cinnabon Rewards!",
    downloadSubtitle: "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    appTitle: "Download the Cinnabon App.",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.cinnabon.com/",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Cinnabon",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.cinnabon.com",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: [],
    disclaimerText: "",
  },
  cinnabonmoes: {
    showHero: true,
    heroBackgroundImage: "/fetchci/hero.png",
    spotlightID: "",
    spotlightTitle: "Download the Cinnabon App.",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    downloadTitle: "Thank you for joining Cinnabon Rewards!",
    downloadSubtitle: "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    appTitle: "Download the Cinnabon App.",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.cinnabon.com/",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Cinnabon",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.cinnabon.com",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: [],
    disclaimerText: "",
  },
  auntieannesschlotzskys: {
    showHero: true,
    heroBackgroundImage: "/auntieannes/aa-cs-hero.png",
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    downloadTitle: "Thank you for joining Auntie Anne's Rewards!",
    downloadSubtitle: "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    appTitle: "Download the Auntie Anne's App.",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.auntieannes.com/",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Auntie Anne's",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.auntieannes.com",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: [],
    disclaimerText: "",
  },
  auntieannesjamba: {
    showHero: true,
    heroBackgroundImage: "/auntieannes/aa-cs-hero.png",
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    downloadTitle: "Thank you for joining Auntie Anne's Rewards!",
    downloadSubtitle: "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    appTitle: "Download the Auntie Anne's App.",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.auntieannes.com/",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Auntie Anne's",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.auntieannes.com",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: [],
    disclaimerText: "",
  },
  moescinnabon: {
    showHero: true,
    heroBackgroundImage: "/moes/moe-cs-hero.png",
    spotlightID: "",
    spotlightTitle: "Download the Moe's App.",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    downloadTitle: "Thank you for joining Moe's Rewards!",
    downloadSubtitle: "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    appTitle: "Download the Moe's App.",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.moes.com/",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest Moe's",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.moes.com",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: [],
    disclaimerText: "",
  },
  mcalisterscinnabon: {
    showHero: true,
    heroBackgroundImage: "/mcalisters/mca-cs-hero.png",
    spotlightID: "",
    spotlightTitle: "Download the Cinnabon App.",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    downloadTitle: "Thank you for joining McAlister's Rewards!",
    downloadSubtitle: "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    appTitle: "Download the McAlister's App.",
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.mcalistersdeli.com/",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest McAlister's Deli",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.mcalistersdeli.com",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: [],
    disclaimerText: "",
  },
  sweepsmca: {
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    pageTitle: "Success! Thank you for entering and best of luck!",
    pageDescription: "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    downloadTitle: "Use your new rewards! Download the Mcalister’s Deli App.",
    downloadSubtitle: ``,
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://www.mcalistersdeli.com/?utm_source=aarp&utm_medium=aff&utm_campaign=fy22_cnv_olo_brand_aarp-microsite-success-page&utm_term=new-customers&utm_content=order-now",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest McAlister's Deli",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.mcalistersdeli.com/?utm_source=aarp&utm_medium=aff&utm_campaign=fy22_con_omni_brand_aarp-microsite-success-page&utm_term=new-customers&utm_content=location-search",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: `NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING. Sweepstakes begins on or about 9/1/23 at 8:00 a.m. ET and ends on 9/30/23 at 11:59 p.m. ET. Open only to legal U.S. residents who are 21 years of age or older. See Official Rules at https://sweeps.mcalistersdeli.com/DrPepperBig12/rules including additional eligibility restrictions, prize descriptions/restrictions/ARVs and complete details. Odds of winning depend on the number of eligible entries received. Limit one (1) entry per person. Void where prohibited. Sponsor: McAlister’s Franchisor SPV LLC. Dr Pepper is a registered trademark of Dr Pepper/Seven Up, Inc. 2023.`,
    disclaimerText: ``,
  },
  sweepsjamba: {
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    pageTitle: "Your entry was received!",
    pageDescription: "Thanks for entering into the Disney Princess – The Concert sweepstakes.",
    downloadTitle: "Use your new rewards! Download the Mcalister’s Deli App.",
    downloadSubtitle: ``,
    buttonGroupTitle: "or",
    orderSubtitle: "Place an order from your computer",
    orderButton: {
      text: "Start An Order",
      URL: "https://www.mcalistersdeli.com/?utm_source=aarp&utm_medium=aff&utm_campaign=fy22_cnv_olo_brand_aarp-microsite-success-page&utm_term=new-customers&utm_content=order-now",
      target: "_blank",
    },
    locationSubtitle: "Find your nearest McAlister's Deli",
    locationButton: {
      text: "Location Search",
      URL: "https://locations.mcalistersdeli.com/?utm_source=aarp&utm_medium=aff&utm_campaign=fy22_con_omni_brand_aarp-microsite-success-page&utm_term=new-customers&utm_content=location-search",
      target: "_blank",
    },
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "",
    benefits: [],
    rewardsDisclaimer: ``,
    disclaimerText: ``,
  },
  channelsaa: {
    showHero: true,
    heroBackgroundImage: "/auntieannes/hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    showBgOnMobile: true,
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've signed up for Auntie Anne's Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "https://focusbrands-dc.azureedge.net/dmm3bwsv3/assetstream.aspx?assetid=2361&mediaformatid=50100&destinationid=10016&download=false",
    spotlightImageAlt: "Auntie Anne's Rewards",
    downloadTitle: "Thank you for joining Auntie Anne's Rewards!",
    downloadSubtitle:
      "",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center"><strong>Tap below to open the Auntie Anne's app and place your order today.</strong></p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download the app in the Apple Store or Google Play using buttons below.</li>
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        <li>Sign into your Auntie Anne's Rewards account using your email and password.</li>
        <br />
        <li>Tap "order" to start your order.</li>
        <br />
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: "",
    locationSubtitle: "",
    locationButton: "",
    showBenefitsSection: false,
    benefitsTitle: "Pretzel Perks Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer: "",
    disclaimerText: "",
  },
  channelsci: {
    showHero: true,
    heroBackgroundImage: "/channelsci/hero.png",
    heroTitle: "",
    heroBrandingLogo: "",
    heroBrandingLogoAlt: "",
    showBgOnMobile: true,
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've signed up for Auntie Anne's Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "https://focusbrands-dc.azureedge.net/dmm3bwsv3/assetstream.aspx?assetid=2361&mediaformatid=50100&destinationid=10016&download=false",
    spotlightImageAlt: "Auntie Anne's Rewards",
    downloadTitle: "Thank you for joining Cinnabon Rewards!​",
    downloadSubtitle:
      "",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center"><strong>Follow the instructions below to place an order.</strong></p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download the app in the Apple Store or Google Play using buttons below.</li>
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/cinnabon/id1532016072" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.cinnfocus.app&hl=en_US&gl=US" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        <li>Sign into your Cinnabon Rewards account using your email and password.​</li>
        <br />
        <li>Tap "order" to start your order.</li>
        <br />
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: "",
    locationSubtitle: "",
    locationButton: "",
    showBenefitsSection: false,
    benefitsTitle: "Pretzel Perks Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer: "",
    disclaimerText: "",
  },
  fetchja: {
    showHero: true,
    heroTitle: "Welcome fetch members",
    heroDescription: "Earn 3,000 Fetch Points when you join Jamba Rewards!",
    heroBackgroundImage: "/fetchja/hero.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Thank you for joining Jamba Rewards!",
    downloadSubtitle:
      "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "",
  },
  jambaauntieannes: {
    showHero: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/fetchja/hero.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Thank you for joining Jamba Rewards!",
    downloadSubtitle:
      "The easiest way to get started with your rewards will be to download our app and sign into your account!",
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "",
  },
  aamuttggenroll: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/auntieannes/gg-points-hero.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Thank you for joining Auntie Anne's Rewards!",
    downloadSubtitle: "",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center">Your GG Points will be deposited shortly.</p>
      <br />
      <p class="info-wrapper text-center">Tap below to open the Auntie Anne's app and place your order today.</p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download the app in the Apple Store or Google Play using buttons below.
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        </li>
        <li>Sign into your Auntie Anne's Rewards account using your email and password.</li>
        <br />
        <li>Tap "order" to start your order</li>
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "",
  },
  aamuttgg5off25: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/auntieannes/5-off-25-hero.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Success! NOW DOWNLOAD THE AUNTIE ANNE’S REWARDS APP TO ACCESS YOUR OFFER.​",
    downloadSubtitle: "",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center"><strong>You must follow the below instructions to access your offer.</strong>​</p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download or open the app in the Apple Store or Google Play using buttons below.​
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        </li>
        <li>Sign into your Auntie Anne’s Rewards account using your email and password.​</li>
        <br />
        <li>Once signed into your Auntie Anne’s Rewards account, tap “rewards”.​</li>
        <br />
        <li>Your rewards and offers will appear. Scroll to find the MUT.GG offer, then tap “Redeem Online” or “Redeem In-Store” to place an order.​</li>
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "Receive $5 off your purchase of $25+ (excluding taxes and fees) at participating locations through February 23, 2024. Must apply Reward at checkout through Rewards Program. Single use. Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. ​©2024 Auntie Anne’s Franchisor SPV LLC. All rights reserved.",
  },
  aamuttggbogo: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/auntieannes/bogo-hero.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Success! NOW DOWNLOAD THE AUNTIE ANNE’S REWARDS APP TO ACCESS YOUR OFFER.​",
    downloadSubtitle: "",
    howItWorks: `
      <p class="info-wrapper text-center"><strong>You must follow the below instructions to access your offer.</strong>​</p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download or open the app in the Apple Store or Google Play using buttons below.​
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        </li>
        <li>Sign into your Auntie Anne’s Rewards account using your email and password.​</li>
        <br />
        <li>Once signed into your Auntie Anne’s Rewards account, tap “rewards”.​</li>
        <br />
        <li>Your rewards and offers will appear. Scroll to find the MUT.GG offer, then tap “Redeem Online” or “Redeem In-Store” to place an order.​</li>
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "Buy one pretzel item, get one pretzel item free at participating locations is valid for 7 days after issue date. Taxes and fees excluded. Discount applies to item of equal or lesser value. All pretzel buckets, dips, catering items, snack packs + protein items (pretzel dog, cheese pretzel, jalapeño & cheese pretzel dog, mini pretzel dogs, and pepperoni pretzel nuggets) qualify for purchase but are excluded from receiving item on us. Single use. Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. ©2024 Auntie Anne’s Franchisor SPV LLC. All rights reserved.",
  },
  aamuttgg5off10: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/auntieannes/5-off-10-hero.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Success! NOW DOWNLOAD THE AUNTIE ANNE’S REWARDS APP TO ACCESS YOUR OFFER.​",
    downloadSubtitle: "",
    howItWorks: `
    <p class="info-wrapper text-center"><strong>You must follow the below instructions to access your offer.</strong>​</p>
    <br />
    <ol class="numbered-list text-center">
      <li>Download or open the app in the Apple Store or Google Play using buttons below.​
      <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
      </li>
      <li>Sign into your Auntie Anne’s Rewards account using your email and password.​</li>
      <br />
      <li>Once signed into your Auntie Anne’s Rewards account, tap “rewards”.​</li>
      <br />
      <li>Your rewards and offers will appear. Scroll to find the MUT.GG offer, then tap “Redeem Online” or “Redeem In-Store” to place an order.​</li>
    </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "The $5 off your purchase of $10+ (excluding taxes and fees) at participating locations is valid for 7 days after issue date. Must apply Reward at checkout through Rewards Program. Single use.  Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. ​©2024 Auntie Anne’s Franchisor SPV LLC. All rights reserved.",
  },
  aamuttgg10off30: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/auntieannes/10-off-30-hero.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "Success! NOW DOWNLOAD THE AUNTIE ANNE’S REWARDS APP TO ACCESS YOUR OFFER.​",
    downloadSubtitle: "",
    howItWorks: `
    <br />
    <p class="info-wrapper text-center"><strong>You must follow the below instructions to access your offer.</strong>​</p>
    <br />
    <ol class="numbered-list text-center">
      <li>Download or open the app in the Apple Store or Google Play using buttons below.​
      <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
      </li>
      <li>Sign into your Auntie Anne’s Rewards account using your email and password.​</li>
      <br />
      <li>Once signed into your Auntie Anne’s Rewards account, tap “rewards”.​</li>
      <br />
      <li>Your rewards and offers will appear. Scroll to find the MUT.GG offer, then tap “Redeem Online” or “Redeem In-Store” to place an order.​</li>
    </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "The $10 off your purchase of $30+ (excluding taxes and fees) at participating locations is valid for 7 days after issue date. Must apply Reward at checkout through Rewards Program. Single use. Not valid with any other offer or reward, or third-party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited. ​©2024 Auntie Anne’s Franchisor SPV LLC. All rights reserved.",
  },
  aatmobile: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/auntieannes/hero-drink-pretzel.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "SUCCESS! NOW DOWNLOAD THE AUNTIE ANNE’S REWARDS APP TO ACCESS YOUR OFFER.",
    downloadSubtitle: "",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center"><strong>You must follow the below instructions to access your offer.</strong></p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download the app in the Apple Store or Google Play using buttons below.</li>
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        <li>Sign into your Auntie Anne's Rewards account using your email and password.</li>
        <br />
        <li>Once signed into your Auntie Anne's Rewards account, tap "rewards".</li>
        <br />
        <li>Your rewards and offers will appear. Scroll to find the T-Mobile Tuesdays offer, then tap "Redeem Online" or "Redeem In-Store" to place an order.</li>
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "The buy one pretzel item or drink, get one pretzel item or drink free offer is valid October 22, 2024 through October 25, 2024, at 11:59 p.m. ET, or close of business. Taxes and fees excluded. Discount applies to item of equal or lesser value. All pretzel buckets, catering items, snack packs + protein items (pretzel dog, cheese pretzel, jalapeño & cheese pretzel dog, mini pretzel dogs and pepperoni pretzel nuggets) qualify for purchase but are excluded from receiving item free. Limit 1 offer/person/loyalty account. Not valid with any other promotion. No substitutions. Service fee applies to online, in-app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void if altered, transferred, reproduced, exchanged, sold, purchased or where restricted by law. Not valid in PR or USVI. Excludes Auntie Anne’s/SUBWAY® locations and most travel plazas and airport locations. Price may vary; cash value 1/100 of 1 cent.TM/©2024 Auntie Anne’s Franchisor SPV LLC. All rights reserved. T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG​​",
  },
  aatmoaug27: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/auntieannes/bogo-hero.png",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "SUCCESS! NOW DOWNLOAD THE AUNTIE ANNE’S REWARDS APP TO ACCESS YOUR OFFER.",
    downloadSubtitle: "",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center"><strong>You must follow the below instructions to access your offer.</strong></p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download the app in the Apple Store or Google Play using buttons below.</li>
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/auntie-annes-pretzel-perks/id733359914" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.auntieannes.pretzelperks" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        <li>Sign into your Auntie Anne's Rewards account using your email and password.</li>
        <br />
        <li>Once signed into your Auntie Anne's Rewards account, tap "rewards".</li>
        <br />
        <li>Your rewards and offers will appear. Scroll to find the T-Mobile Tuesdays offer, then tap "Redeem Online" or "Redeem In-Store" to place an order.</li>
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "The buy one, get one pretzel item free offer is valid August 27, 2024 through August 30, 2024, at 11:59 p.m. ET, or close of business. Taxes and fees excluded. Discount applies to item of equal or lesser value. All pretzel buckets, catering items, snack packs + protein items (pretzel dog, cheese pretzel, jalapeño & cheese pretzel dog, mini pretzel dogs, pepperoni pretzels and pepperoni pretzel nuggets) qualify for purchase but are excluded from receiving item free. Limit 1 offer/person/loyalty account. Not valid with any other promotion. No substitutions. Service fee applies to online, in-app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void if altered, transferred, reproduced, exchanged, sold, purchased or where restricted by law. Not valid in PR or USVI. Excludes Auntie Anne’s/SUBWAY® locations and most travel plazas and airport locations. Price may vary; cash value 1/100 of 1 cent.TM/©2024 Auntie Anne’s Franchisor SPV LLC. All rights reserved. T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG​",
  },
  schlotzskystmobile: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/schlotzskys/tmobile-hero-09-10-24.jpg",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "SUCCESS! What's next?​",
    downloadSubtitle: "",
    howItWorks: `
      <br />
      <p class="info-wrapper text-center">Follow the below instructions to access your offer.</p>
      <br />
      <ol class="numbered-list text-center">
        <li>Download the Schlotzsky's app (in the Apple Store or Google Play Store).</li>
        <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/schlotzskys-rewards-program/id768163905" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.punchh.schlotzskys&hl=en_US&gl=US" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
        <li>Sign into your Schlotzsky’s Rewards account using your email and password.</li>
        <br />
        <li>Tap "rewards". Your rewards and offers will appear. Scroll to find the T-mobile offer, then tap "Redeem Online" or "Redeem In-Store" to place an order.</li>
        <br />
        <li>To access your offer via <a href="https://www.schlotzskys.com" target="_blank">www.schlotzskys.com</a>, click "order now", then sign in with your email and password.</li>
        <br />
        <li>Click "Your Account", then "Offers", then click "View Rewards" for your offer.</li>
      </ol>
    `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
      disclaimerText:
      "The free Small Sandwich with purchase of a Regular Drink offer is valid September 10, 2024 through September 16, 2024, at 11:59 p.m. ET, or close of business. Taxes and fees excluded. No substitutions, excludes add-ons, extra toppings or gluten free. Reward can be redeemed using a valid Schlotzsky’s Rewards account at participating locations, in store, on Schlotzskys.com, or in the Schlotzsky’s app. Limit 1/person/loyalty account. Must apply Reward at checkout. Not valid with any other offer or reward, or third party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.​",
    disclaimerTextAdditional: `<div class="container disclaimer-additional" style="padding-top: 10px;"><p style="padding-bottom: 10px;">
T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG.​</p> <p>© 2024 The Coca-Cola Company.</p></div>`,
  },
  mcalisterstmobile: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/mcalisters/herotmobile.jpg",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "SUCCESS! WHAT’S NEXT?​​",
    downloadSubtitle: "",
    howItWorks: `
        <br />
        <p class="info-wrapper text-center">Follow the below instructions to access your offer.</p>
        <br />
        <ol class="numbered-list text-center">
          <li>Download the McAlister's app (in the Apple Store or Google Play Store) or login to your McAlister's Rewards account via <a href="https://mcalistersdeli.com" target="_blank">mcalistersdeli.com</a> with your email and password.</li>
          <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/mcalisters-deli/id475738160" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.snapfinger.mobile.mcalisters&hl=en_US&gl=US" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
          <br />
          <li>Once signed into your McAlister's Rewards account, tap "My Rewards", and then tap "Special Offers"</li>
          <br />
          <li>Your rewards and offers will appear. Scroll to find the T-Mobile Tuesdays offer, then tap "Redeem Online" or "Redeem In-Store" to place an order.</li>
        </ol>
      `,
    buttonGroupTitle: "",
    orderSubtitle: "Place an order from your computer",
    orderButton: undefined,
    locationSubtitle: "Find your nearest Jamba",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "Jamba Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/mcalisters/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "",
      },
      {
        imageURL: "/mcalisters/tea.png",
        imageAlt: "This Pretzel is On Us!",
        title: "This Pretzel is On Us!",
        description:
          "You'll receive a pretzel reward after you make your first purchase of at least $1.00",
        note: "",
      },
      {
        imageURL: "/mcalisters/birthday.png",
        imageAlt: "Pretzel Rewarded Every 250 Points!",
        title: "Pretzel Rewarded Every 250 Points!",
        description: "Receive a pretzel by earning 250 points. $1 spent equals 10 points.",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
      disclaimerText: `
      <p>The $5 off Entree (Choose 2, Sandwiches, Salads, Spuds, Shareables or Bowls of Soup) Reward is valid September 24, 2024 through September 27, 2024, at 11:59 p.m. ET or close of business. Taxes and fees excluded. No substitutions, excludes Kids Meals. Reward must be redeemed using a valid McAlister’s Rewards account at participating locations, in store, online at McAlistersDeli.com, or in the McAlister’s Deli app. Limit 1/person/loyalty account. Must apply Reward at checkout. Not valid with any other offer or reward, or third party delivery. Service fee applies to online, in app, and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void where prohibited.</p><br>
      <p>T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG.</p><br>
      <p>DR PEPPER is a registered trademark of Dr Pepper/Seven Up, Inc. ©2024 Dr Pepper/Seven Up, Inc.</p><br>
      <p>TM/© McAlister’s Franchisor SPV LLC.</p>`,
  },
  cinnabontmobile: {
    showHero: true,
    showBgOnMobile: true,
    heroTitle: "",
    heroDescription: "",
    heroBackgroundImage: "/cinnabon/herotmobile.jpg",
    spotlightID: "You're All Set!",
    spotlightTitle: "Your accounts were linked!",
    spotlightBrandingLogo: "/common/aarp-benefits.png",
    spotlightBrandingLogoAlt: "AARP Logo",
    spotlightDescription: [
      {
        text: "Way to go! Now that you've linked your AARP membership to Jamba Rewards, you'll be unlocking a bunch of benefits that we hope you'll enjoy!",
      },
    ],
    spotlightImageURL: "/jamba/spotlight.png",
    spotlightImageAlt: "Jamba Rewards",
    downloadTitle: "SUCCESS! NOW DOWNLOAD THE CINNABON APP TO ACCESS YOUR OFFER​​",
    downloadSubtitle: "",
    howItWorks: `
        <br />
        <p class="info-wrapper text-center">You must follow the below instructions to access your offer</p>
        <br />
        <ol class="numbered-list text-center">
          <li>Download the app in the Apple Store or Google Play using buttons below.</li>
          <div class="app-link-component"><h3 class="section-title"></h3><a href="https://apps.apple.com/us/app/cinnabon/id1532016072" target="_blank"><img src="/common/app-store.svg" alt="Download on App Store"></a><a href="https://play.google.com/store/apps/details?id=com.cinnfocus.app&hl=en_US&gl=US" target="_blank"><img src="/common/google-play.svg" alt="Download on Google Play"></a></div>
          <br />
          <li>Sign into your Cinnabon Rewards account using your email and password.</li>
          <br />
          <li>Once signed into your Cinnabon Rewards account, tap "rewards" at the bottom of the screen, then tap "rewards" again in the middle of the screen.</li>
          <br />
          <li>Your rewards and offers will appear. Scroll to find the T-Mobile Tuesdays offer, then tap "redeem" to place an order.</li>
          </ol>
      `,
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: undefined,
    locationSubtitle: "",
    locationButton: undefined,
    showBenefitsSection: false,
    benefitsTitle: "",
    benefitsTitleLogo: "/",
    benefits: [
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description:
          "",
        note: "",
      },
      {
        imageURL: "",
        imageAlt: "",
        title: "",
        description: "",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "",
    disclaimerText:
      "The buy one baked good (Classic Roll, MiniBon, 4-count BonBites or Center of the Roll), get one baked good item free offer is valid October 15, 2024 through October 20, 2024, at 11:59 p.m. ET, or close of business at participating Cinnabon bakery locations. At Cinnabon co-branded stores and Dual Branded Bakeries, customers must place an order for pick-up only within Cinnabon app; in-store purchases not applicable for these locations. Taxes and fees excluded. Discount applies to item of equal or lesser value. Limit 1 offer/person/loyalty account. Not valid with any other promotion. No substitutions. Service fee applies to online, in-app and certain call-in orders, other fees and taxes may apply for all orders; see checkout for details. Void if altered, transferred, reproduced, exchanged, sold, purchased or where restricted by law. Not valid in PR or USVI. Price may vary; cash value 1/100 of 1 cent.TM/©2024 Cinnabon Franchisor SPV LLC. All rights reserved. T-Mobile, the T logo, Magenta, and the magenta color are trademarks of Deutsche Telekom AG",
  },
  sweepsmoes: {
    pageTitle: "You're Registered!",
    pageDescription: "",
    pageDescriptionHTML: "Thanks for registering for the Moe’s Sounds of Summer Sweepstakes. To complete your entry, make a drink purchase using your Moe Rewards account or see official rules for no purchase methods of entry.* For additional entries, you may enter one time per day through 7/25/23 with a purchase of a drink at Moe’s or make a no purchase entry pursuant to the rules.",
    spotlightID: "",
    spotlightTitle: "",
    spotlightBrandingLogo: "",
    spotlightBrandingLogoAlt: "",
    spotlightDescription: [
      {
        text: "",
      },
    ],
    spotlightImageURL: "",
    spotlightImageAlt: "",
    downloadTitle: "",
    downloadSubtitle:
      "",
    buttonGroupTitle: "",
    orderSubtitle: "",
    orderButton: {
      text: "Start An Order",
      URL: "https://order.moes.com/?utm_source=aarp&utm_medium=aff&utm_campaign=fy22_cnv_olo_brand_aarp-microsite-success-page&utm_term=new-customers&utm_content=order-now ",
      target: "_blank",
    },
    locationSubtitle: "",
    showBenefitsSection: true,
    benefitsTitle: "Moe Rewards Benefits for AARP Members",
    benefitsTitleLogo: "/common/aarp-benefits.png",
    benefits: [
      {
        imageURL: "/moes/10percent.png",
        imageAlt: "10% Off Each Day",
        title: "10% Off Each Day",
        description: "The more often you visit, the more you save!",
        note: "Cannot be combined with other rewards; excludes catering and third party delivery orders.",
      },
      {
        imageURL: "/moes/freechip.png",
        imageAlt: "Free Cup of Queso",
        title: "Free Cup of Queso",
        description:
          "Welcoming you to our fam the only way we know how. But this is just the beggining",
        note: "",
      },
      {
        imageURL: "/moes/birthday.png",
        imageAlt: "Birthday Burritos",
        title: "Birthday Burritos",
        description: "It's your day to be celebrated. Enjoy a burrito on us!",
        note: "",
      },
    ],
    rewardsDisclaimer:
      "*NO PURCHASE NECESSARY. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. SEE FULL OFFICIAL RULES FOR NO PURCHASE METHOD OF ENTRY: HTTPS://SWEEPS.MOES.COM/SOUNDSOFSUMMER/RULES. OPEN ONLY TO LEGAL RESIDENTS 50 U.S. & D.C., 18 YEARS+. VOID WHERE PROHIBITED. BEGINS 9:00 A.M. ET ON 6/14/2023 AND ENDS AT 11:00 P.M. ET 7/25/2023. ARV OF ALL PRIZES: $19,950. ODDS OF WINNING DEPEND ON THE TOTAL NUMBER OF ENTRIES RECEIVED. SUBJECT TO FULL OFFICIAL RULES. SPONSOR: MOE’S FRANCHISOR SPV LLC.",
    disclaimerText:
      "",
  },
};
